<template>
  <v-dialog v-if="service.openDialog" :value="service.openDialog" persistent>
    <v-card v-if="service.jobDating">
      <v-card-title>Votre participation au job dating de {{ service.jobDating.location }}
      </v-card-title>
      <v-card-text>
        {{ service.jobDating.label }}
      </v-card-text>
      <v-card-text v-if="!service.finished">
        Merci de nous indiquer sur quel créneau vous souhaitez venir&nbsp;:
        <v-select v-model="service.selectedSlot"
                  hint="Merci de préciser un créneau"
                  dense
                  persistent-hint
                  :items="service.slotsForSelect"/>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="success" outlined v-if="service.isNoJDSelected">
          Merci, nous avons pris en compte votre réponse.
        </v-alert>
        <v-alert type="success" outlined v-else>
          Nous vous avons envoyé un mail confirmant votre inscription.
          <br/>Merci et à bientôt&nbsp;!
        </v-alert>
      </v-card-text>
      <v-card-text v-if="service.error">
        <v-alert type="error" outlined>
          {{ service.error }}
        </v-alert>
      </v-card-text>
      <v-card-actions v-if="!service.finished">
        <v-btn :loading="service.loading" color="error" small @click="service.close()">Annuler</v-btn>
        <v-spacer/>
        <v-btn :loading="service.loading" color="shiny" @click="service.submit()">Confirmer</v-btn>
      </v-card-actions>
      <v-card-actions v-else class="text-center center-text justify-center">
        <v-btn color="shiny" @click="service.close()">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import JobDatingService from '@/components/job-dating/JobDatingService';

export default {
  data() {
    return {service: new JobDatingService()};
  },
};
</script>

<style scoped>
</style>

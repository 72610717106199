import SafeService from 'odas-plugins/SafeService';
import {JobDating} from 'erhgo-api-client';
import Vue from 'vue';
import keycloakService from 'odas-plugins/KeycloakService';

export default class JobDatingService extends SafeService {

  private static JD_CODE_KEY = 'jdcode';
  public static NO_JD_CODE = 'NO_JD';
  private _jdCode = '';
  private _jobDating: JobDating | null = null;
  private _error = '';
  private static globalJdcode = '';
  private _finished = false;

  selectedSlot: string | null = null;

  constructor() {
    super();
    this._jdCode = JobDatingService.globalJdcode;
    if (this._jdCode) {
      this.fetchJobDating();
    }
  }

  private async fetchJobDating() {
    await this.safeCall(async () => {
      this._jobDating = (await Vue.$api.getJobDating(this._jdCode)).data;
    });
  }

  async submit() {
    if (this.selectedSlot) {
      this._error = '';
      await this.safeCall(async () => Vue.$api.affectJobDatingSlot({
        userId: keycloakService.userId,
        slotId: this.selectedSlot === JobDatingService.NO_JD_CODE ? undefined : this.selectedSlot!,
        jobDatingId: this._jobDating?.id,
      }));
      if (this.showAPIError) {
        this._error = 'Une erreur est survenue : veuillez réessayer ou contacter le support si le problème persiste.';
      } else {
        this._finished = true;
      }
    } else {
      this._error = 'Veuillez choisir un créneau horaire.';
    }
  }

  get jobDating() {
    return this._jobDating;
  }

  get openDialog() {
    return this.loading || this.jobDating;
  }

  get error() {
    return this._error;
  }

  get finished() {
    return this._finished;
  }

  close() {
    this._jobDating = null;
    this._jdCode = '';
  }

  static extractJdCodeFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(JobDatingService.JD_CODE_KEY)) {
      JobDatingService.globalJdcode = urlParams.get(JobDatingService.JD_CODE_KEY) || '';
      return JobDatingService.globalJdcode;
    }
    return '';
  }

  get slotsForSelect() {
    return this._jobDating ? [{
      text: 'Non, je ne souhaite pas venir',
      value: JobDatingService.NO_JD_CODE,
    }, ...this._jobDating.slots.map(s => ({
      text: s.label,
      value: s.id,
    }))] : [];
  }

  get isNoJDSelected() {
    return this.selectedSlot === JobDatingService.NO_JD_CODE;
  }
}

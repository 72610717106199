import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'odas-plugins';
// @ts-ignore
import vuetify from 'odas-plugins/vuetify';
import Vue from 'vue';
// @ts-ignore
import App from './App.vue';
// @ts-ignore
import router from './router';
import store from './store';
import {initKeycloak} from 'odas-plugins/keycloak';
import {baseUrl} from 'odas-plugins/base-url';
import userProfileInitializer from '@/domain/user/UserProfileInitializer';
import {logErrorToServer} from 'odas-plugins/error-handler';
// @ts-ignore
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import JobDatingService from '@/components/job-dating/JobDatingService';
import {storeThenGetContextFromMobileApp} from '@/mobile-utils';

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

storeThenGetContextFromMobileApp(Vue.$router.currentRoute);

const eventBus = new Vue();
Vue.$eventBus = eventBus;
Vue.prototype.$keycloak = eventBus;

const initOptionsFO = {
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  url: process.env.VUE_APP_KEYCLOAK_URL || baseUrl('auth'),
  sslRequired: process.env.VUE_APP_KEYCLOAK_SSL_REQUIRED,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
};
try {
  initKeycloak(
    initOptionsFO,
    'check-sso',
    async () => {
      // FIXME ERHGO-75 : channel assignation must precede app initialization in a blocking way
      // to avoid any concurrent request creating UserProfile on app part - remove await
      // when this workflow is refactored
      try {
        await userProfileInitializer.initialize();
      } catch (e) {
        logErrorToServer(e, undefined, 'Error on profile init');
        throw e;
      }
      JobDatingService.extractJdCodeFromUrl();
      try {
        new Vue({
          // @ts-ignore
          vuetify,
          // @ts-ignore
          store,
          // @ts-ignore
          router,
          // eslint-disable-next-line
            render: h => h(App),
        }).$mount('#app');
      } catch (e) {
        logErrorToServer(e, undefined, 'Error on Vue init');
        throw e;
      }
    },
    true,
  );
} catch (e) {
  logErrorToServer(e, undefined, 'Global error!');
  throw e;
}
